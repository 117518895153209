.cases-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 10px;
}

.case-box {
  width: calc(33.333% - 20px);
  border-radius: 10px;
  height: 300px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
#cases h1 {
  font-size: 70px;
}
/* Tablet view */
@media (max-width: 768px) {
  .case-box {
    width: calc(50% - 20px);
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .case-box {
    width: calc(100% - 20px);
  }
  #cases h1 {
    font-size: 40px;
  }

  .case-box {
    height: 200px;
  }
}
