nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 13vh;
  position: fixed;
  width: 100vw;
  z-index: 100;
  transition: ease-in-out 0.1s;
}

.nav-links {
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

.a-nav {
  color: white;
  text-decoration: none;
  font-weight: 600;
  text-decoration-color: white;
}

.a-nav:hover {
  color: #ffd700;
  text-decoration: underline;
  text-underline-offset: 1rem;
  font-weight: 600;
  text-decoration-color: #ffd700;
}

.logo:hover {
  cursor: pointer;
}

/* HAMBURGER MENU */

#hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3 ease-in-out;
}

.menu-links {
  position: absolute;
  top: 70px;
  right: 0;
  width: 100vw;

  background-color: white;

  transition: all 0.3s ease-in-out;
}

.menu-links .a-nav {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 300px;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child {
  transform: none;
}

.hamburger-icon span:first-child {
  opacity: 1;
}

.hamburger-icon span:first-child {
  transform: none;
}

@media screen and (max-width: 1200px) {
  #desktop-nav {
    display: none;
  }
  #hamburger-nav {
    display: flex;
    position: fixed;
    width: 100vw;
    z-index: 20;
  }

  .separator {
    width: 100%;

    height: 2px;
    background-color: white;
  }
  nav {
    justify-content: space-around;
    align-items: center;
    height: 13vh;
    transition: ease-in-out 0.1s;
  }

  .contact-info-container p,
  .nav-links li a {
    font-size: 1rem;
  }

  .logo img {
    height: 50px;
  }
  .nav-links {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }

  .menu-links {
    width: 100vw;
    position: fixed;
    background-color: rgba(53, 53, 53, 0.95);
    text-align: center;
    justify-content: center;
    display: grid;
    top: 13vh;
    left: 0;
    height: 67vh;
    transition: ease-in-out 0.2s;
  }
}
