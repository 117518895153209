#about {
  display: grid;
  padding-top: 5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  text-align: center;
  margin-top: 50px;
  justify-content: center;
  row-gap: 50px;
}

.imgAndText1 {
  height: 30%;
  width: 100%;

  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.imgAboutContainer {
  width: 300px;
  height: 300px;
  box-shadow: 10px 10px 5px gray;
  border-radius: 10px;
}

.imgAboutContainer img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.logoAndParagraph {
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  border-radius: 10px;
  height: 200px;
  background: white;
  padding: 50px;
  width: 70%;
  box-shadow: 10px 10px 5px gray;
}

.logoAndParagraph img {
  width: 40px;
  height: 40px;
}

.logoAndParagraph p {
  font-size: 25px;
  font-weight: 700;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-active {
  opacity: 1;
  transform: translateX(0);
}
#about h1 {
  font-size: 70px;
}

@media (max-width: 480px) {
  #about {
    display: grid;
    padding-top: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    text-align: center;
    margin-top: 50px;
    justify-content: center;
    row-gap: 50px;
    overflow: hidden;
  }

  .imgAndText1 {
    height: 30%;
    width: 100%;

    display: grid;
  }

  .imgAboutContainer {
    width: 300px;
    height: 300px;
    box-shadow: 10px 10px 5px gray;
    border-radius: 10px;
  }

  .imgAboutContainer img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .logoAndParagraph {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid gray;
    border-radius: 10px;
    height: 270px;
    background: white;
    padding: 15px;
    width: 270px;

    box-shadow: 10px 10px 5px gray;
  }

  .logoAndParagraph img {
    width: 40px;
    height: 40px;
  }
  .logoAndParagraph p {
    font-size: 20px;
  }
  .fade-in-left {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  .fade-in-right {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  .fade-in-active {
    opacity: 1;
    transform: translateX(0);
  }
  #about h1 {
    font-size: 40px;
  }
}
