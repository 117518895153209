.case-card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid gray;
}

.case-card img {
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
}

.overlay {
  position: absolute;
  bottom: -75%; /* starts hidden */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8); /* semi-transparent background */
  overflow: hidden;
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  transition: bottom 0.5s ease-in-out;
}

.overlay a {
  color: #ffd700;
}

.case-card:hover .overlay {
  bottom: 0; /* swaps up */
}

.case-card:hover img {
  transform: scale(1.2); /* scaling image */
}

@media (max-width: 480px) {
  .overlay h4 {
    font-size: 10px;
  }
}
