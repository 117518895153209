/* FOOTER SECTION */
.nav-links-container-footer {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}
.nav-links-footer {
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 0;
  font-size: 25px;
}

.nav-links-footer li a {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.nav-links-footer li a:hover {
  text-decoration: none;
  color: gray;
  font-weight: 500;
}
footer {
  height: 15vh;
  margin: 0 1rem;
}

footer p {
  text-align: center;
}

.gridCases {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 480px) {
  .nav-links-footer {
    list-style: none;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 15px;
    padding: 0;
    font-size: 20px;
  }
}
