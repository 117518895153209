/* CONTACT */

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: white;
  background: (250, 250, 250);
  margin: 2rem auto;
  color: black;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container {
  color: black;
}
.contact-info-container p {
  font-size: larger;
}
.contact-info-container p a {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.contact-info-container p a:hover {
  text-decoration: none;
  color: gray;
  font-weight: 500;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

.title-contact {
  font-size: 70px;
}
#contact {
  text-align: center;
}

@media (max-width: 480px) {
  .contact-info-upper-container {
    width: 80%;
    font-size: 10px;
    display: flex;
    justify-content: left;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    background: (250, 250, 250);
    margin: 2rem auto;
    color: black;
    padding: 0.5rem;
  }
  #contact {
    text-align: center;
  }
  .title-contact {
    font-size: 40px;
  }
}
